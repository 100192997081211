.form {
  &-radios {
    display: grid;
    gap: 10px;
    position: relative;
    width: max-content;
    li {
      display: flex;
      align-items: center;
      gap: 5px;
      position: relative;
    }
    input {
      opacity: 0;
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }

  &__radio {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 14px;
    height: 14px;
    margin-right: 2px;

    border: 1px solid #6a6cf7;
    border-radius: 50%;

    &::before {
      display: block;
      content: "";
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: #fff;
    }

    &.active {
      margin-right: 0;
      width: 16px;
      height: 16px;
      background-color: #6a6cf7;
    }
  }
}
