.sk {
  width: 100%;
  height: 15px;
  display: block;
  border-radius: 4px;
  background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.3),
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0.3) 80%
    ),
    lightgray !important;
  background-repeat: repeat-y;
  background-size: 50px 500px;
  background-position: -150% 0;
  animation: shine 1.2s cubic-bezier(0.55, 0.09, 0.68, 0.53) infinite;
}

@keyframes shine {
  to {
    background-position: 200% 0;
  }
}
