.layout {
  display: grid;
  grid-template-rows: auto 1fr;
  min-height: 100vh;
}

.page-wrapper {
  display: grid;
  position: relative;
  height: 100%;
  overflow: hidden;
  background-color: #f6f6f6;

  @media (max-width: 768px) {
    display: block;
    padding-bottom: 70px;
  }
}

.page-content {
  overflow: hidden;
  height: 100%;
}

.navbar {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 20;
  background-color: #fff;
  border-bottom: 1px solid #f3f3f3;

  .avatar {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 50%;
  }

  .logo {
    height: 42px;
    max-width: 100px;
    max-height: 42px;
    margin-top: -3px;
    opacity: 1;

    path {
      fill: #262626 !important;
    }

    &:hover {
      path {
        fill: red !important;
      }
    }
  }

  svg {
    opacity: 0.5;
  }

  a {
    display: flex;
    flex-direction: column;
    align-items: center;

    font-weight: 400;
    line-height: 1.3125rem;
    border-bottom: 0.125rem solid transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: clamp(0.31rem, calc(0.1rem + 1.04vw), 0.63rem)
      clamp(0.31rem, calc(-0.15rem + 2.29vw), 1rem) clamp(0.31rem, calc(0.1rem + 1.04vw), 0.63rem);
    color: #727c8e;
    transform: translateY(1px);

    p {
      font-size: 14px;
      margin-top: 0.3125rem;
      color: #727c8e;
    }

    &:hover {
      svg {
        opacity: 1;
      }
      path {
        fill: red;
      }

      p {
        color: red;
      }
    }
  }

  .active {
    border-bottom: 2px solid #2997ff;
    svg {
      opacity: 1;
    }
    path {
      fill: red;
    }
    p {
      color: #2997ff;
    }
  }

  .brand {
    margin: 0;
    font-family: "Berkshire Swash", cursive;
    font-size: 2.125rem;
    line-height: 2.625rem;
    font-weight: 400;
    color: #262626;
    border-bottom: none;
    font-weight: initial;
  }

  .dashboard-icon {
    height: 15px;
    overflow: hidden;

    svg {
      width: 18px;
    }
  }

  .navigation {
    display: flex;
    gap: 10px;
  }

  .bars {
    display: none;
    width: 25px;
    margin-left: 10px;
    max-width: 25px;
    svg {
      margin-right: 5px;
    }
  }

  .dropdown__btn {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__mobile {
    display: none;
    width: 100%;
    position: fixed;
    z-index: 10000;
    right: 0;
    bottom: 0;

    &-content {
      display: flex;
      justify-content: space-around;
      position: relative;
      background-color: #fff;
      margin-left: auto;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }

    a {
      flex-direction: column;
      justify-content: flex-start;
      gap: 5px;
      padding: 10px 16px 12px;

      &.active {
        border-bottom: 0;
      }
      p {
        font-size: 12px;
        margin-top: 0;
      }
    }
  }

  @media (max-width: 768px) {
    .bars {
      display: block;
    }

    &__mobile {
      display: block;
    }
    .navigation {
      display: none;
    }

    .container {
      display: var(--inner-page-navbar, "block");
    }
  }
}

@media (max-width: 768px) {
  .navbar {
    .logo {
      max-width: 80px;
    }
  }
}
