.ck {
  ul,
  ol {
    padding-left: 20px;
  }
  * {
    color: #000;
  }
  strong {
    font-weight: 700;
  }

  i {
    font-style: italic;
  }

  a {
    text-decoration: underline;
    color: mediumblue;
  }
  &-editor {
    position: relative;
    z-index: 0;
  }
}
