.react-datepicker {
  width: max-content;
  border: none;
  border-radius: 0;

  label {
    display: block !important;
    font-size: 14px;
  }

  &__wrapper {
    width: max-content;
  }

  &__input-container {
    width: 200px;
    border: 1px solid rgb(207, 215, 225);
    border-radius: 4px;

    input {
      width: 100%;
      border-radius: 4px;
      height: 36px;
      outline: none;
      padding: 2px 5px;
      font-size: 13px;
    }
  }

  &__day--selected {
    color: #fff !important;
  }

  &__month-container {
    border: 1px solid rgb(207, 215, 225);
    border-radius: 4px;
  }
}
