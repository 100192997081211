.form {
  &-select {
    display: flex;
    align-items: center;
    width: 100%;
    border: 1px solid #cfd7e1;
    border-radius: 4px;
    padding: 3px;
    min-height: 37px;

    &.disabled {
      background-color: rgba(59, 59, 59, 0.1);
    }

    &__header {
      width: 100%;
    }

    &__selected {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 5px;
      button {
        padding: 3px;
      }
    }

    &__btn {
      display: flex;
      align-items: center;
      position: relative;
      border-radius: 4px;
      padding: 3px 6px;
      background-color: transparent;

      svg {
        width: 17px;
        opacity: 0.5;
      }

      input {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 3;
        top: 0;
        left: 0;
        opacity: 0;
      }

      &-text {
        margin-left: 2px;
        color: #536061;
      }

      &:hover {
        background-color: rgba(215, 225, 234, 0.7);
      }
    }

    .btn {
      display: flex;
      align-items: center;
      padding: 0;
    }

    &__actions {
      display: flex;
      align-items: center;
      gap: 10px;
      opacity: 0.5;
      svg {
        min-width: 18px;
      }

      .x-icon {
        margin-top: 2px;
        min-width: 11px;
      }
    }

    &__list {
      display: grid;
      gap: 15px;
      padding: 5px;
      margin-top: 2px;
      border-top: 1px solid #cfd7e1;
    }

    &__item {
      display: flex;
      gap: 10px;
      border-radius: 4px;
      padding: 5px;

      &:hover {
        background-color: rgba(215, 225, 234, 0.7);
      }

      img {
        max-width: 93px;
      }

      &-img {
        position: relative;
      }

      &-delete {
        display: none;
        align-items: center;
        justify-self: center;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        z-index: 1;
        background-color: #53606169;

        svg {
          width: 30px;
          height: auto;
          margin: 0 auto;
        }

        path {
          fill: #536061;
        }
      }

      &:hover {
        .form-upload__item-delete {
          display: flex;
        }
      }
    }
  }
}

.select-modal {
  width: 100%;

  .form-group {
    padding-bottom: 0;
  }

  .rc-table {
    margin-top: 0;
    border-top: none;
    border-radius: 0;
  }

  .list__cell {
    color: rgb(83, 96, 97);
  }

  th {
    color: rgb(83, 96, 97) !important;
  }

  &__close-icon {
    width: 12px;
    opacity: 0.5;
  }
  &.table {
    max-width: calc(100vw - 50px);
    margin-top: 150;
    margin-top: 0;
    border: none;
  }
  form {
    display: flex;
    align-items: center;
  }

  .table__header {
    padding: 0;
    border-bottom: 1px solid #cfd7e1;
    position: relative;
    .form-group {
      width: 100%;
    }
    input {
      width: 100%;
      border: none;
    }
    label {
      display: none;
    }
    svg {
      position: absolute;
      top: 12px;
      right: 20px;
      opacity: 0.5;
    }
    .search {
      width: 15px;
    }

    .xicon {
      width: 12px;
    }
  }

  &__title {
    font-size: 18px;
  }

  .list__row {
    cursor: pointer;
  }

  &__bottom {
    padding: 20px;
  }
}
