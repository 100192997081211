.form {
  &-radio-btns {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 10px;
    position: relative;

    li {
      display: flex;
      align-items: center;
      gap: 5px;
      position: relative;
      padding: 8px 10px;
      border-radius: 0.3125rem;

      background-color: white;
      color: #0b2e13;
      border: 1px solid rgba(0, 0, 0, 0.2);
      box-shadow: none;
      font-weight: 500;

      &.disabled {
        background-color: rgba(0, 0, 0, 0.1);
      }
    }

    input {
      opacity: 0;
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }

  &__radio-btn {
    cursor: pointer;

    &.active {
      border: 2px solid red;
    }
  }
}