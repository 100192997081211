.search-wrap {
  display: flex;
  height: 36px;

  * {
    border-radius: 0;
  }

  input {
    border: 1px solid #ced4da;

    &:focus {
      border: 1px solid #ced4da;
      box-shadow: none;
    }
  }

  .icon {
    border-radius: 6px 0 0 6px;
  }

  .submit {
    border-radius: 0 6px 6px 0;
  }

  button {
    border-right: 0;
    height: 100%;

    svg {
      width: 12px;
      opacity: 0.5;
    }
  }
}
