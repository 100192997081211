:root {
  --bs-blue: #0090c0;
  --bs-indigo: #6a6cf7;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #ff3e1d;
  --bs-orange: #e67e22;
  --bs-yellow: #f0ad4e;
  --bs-green: #86cb43;
  --bs-teal: #6bc48d;
  --bs-cyan: #5bc0de;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #72809d;
  --bs-gray-dark: #35425b;
  --bs-gray-100: #f0f4f8;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #d7e1ea;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #72809d;
  --bs-gray-700: #506880;
  --bs-gray-800: #35425b;
  --bs-gray-900: #212529;
  --bs-primary: #6a6cf7;
  --bs-secondary: #72809d;
  --bs-success: #86cb43;
  --bs-info: #5bc0de;
  --bs-warning: #e67e22;
  --bs-danger: #ff3e1d;
  --bs-light: #f0f4f8;
  --bs-dark: #212529;
  --bs-primary-rgb: 106, 108, 247;
  --bs-secondary-rgb: 114, 128, 157;
  --bs-success-rgb: 134, 203, 67;
  --bs-info-rgb: 91, 192, 222;
  --bs-warning-rgb: 230, 126, 34;
  --bs-danger-rgb: 255, 62, 29;
  --bs-light-rgb: 240, 244, 248;
  --bs-dark-rgb: 33, 37, 41;
  --bs-primary-text: #00739a;
  --bs-secondary-text: #72809d;
  --bs-success-text: #6ba236;
  --bs-info-text: #377385;
  --bs-warning-text: #90682f;
  --bs-danger-text: #cc3217;
  --bs-light-text: #72809d;
  --bs-dark-text: #506880;
  --bs-primary-bg-subtle: #cce9f2;
  --bs-secondary-bg-subtle: #f0f4f8;
  --bs-success-bg-subtle: #e7f5d9;
  --bs-info-bg-subtle: #def2f8;
  --bs-warning-bg-subtle: #fcefdc;
  --bs-danger-bg-subtle: #ffd8d2;
  --bs-light-bg-subtle: #f8fafc;
  --bs-dark-bg-subtle: #ced4da;
  --bs-primary-border-subtle: #99d3e6;
  --bs-secondary-border-subtle: #e9ecef;
  --bs-success-border-subtle: #cfeab4;
  --bs-info-border-subtle: #bde6f2;
  --bs-warning-border-subtle: #f9deb8;
  --bs-danger-border-subtle: #ffb2a5;
  --bs-light-border-subtle: #e9ecef;
  --bs-dark-border-subtle: #adb5bd;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 51, 51, 51;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans",
    "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New",
    monospace;
  --bs-gradient: linear-gradient(180deg, hsla(0, 0%, 100%, 0.15), hsla(0, 0%, 100%, 0));
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.428571429;
  --bs-body-color: #333;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: rgba(51, 51, 51, 0.75);
  --bs-secondary-color-rgb: 51, 51, 51;
  --bs-secondary-bg: #e9ecef;
  --bs-secondary-bg-rgb: 233, 236, 239;
  --bs-tertiary-color: rgba(51, 51, 51, 0.5);
  --bs-tertiary-color-rgb: 51, 51, 51;
  --bs-tertiary-bg: #f0f4f8;
  --bs-tertiary-bg-rgb: 240, 244, 248;
  --bs-body-bg: #fafbfd;
  --bs-body-bg-rgb: 250, 251, 253;
  --bs-link-color: #3498db;
  --bs-link-color-rgb: 52, 152, 219;
  --bs-link-decoration: none;
  --bs-link-hover-color: #2a7aaf;
  --bs-link-hover-color-rgb: 42, 122, 175;
  --bs-link-hover-decoration: underline;
  --bs-code-color: #d63384;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #d7e1ea;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.3rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.35rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 0.5rem 1rem rgba(var(--bs-body-color-rgb), 0.15);
  --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(var(--bs-body-color-rgb), 0.075);
  --bs-box-shadow-lg: 0 1rem 3rem rgba(var(--bs-body-color-rgb), 0.175);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(var(--bs-body-color-rgb), 0.075);
  --bs-emphasis-color: #000;
  --bs-form-control-bg: var(--bs-body-bg);
  --bs-form-control-disabled-bg: var(--bs-secondary-bg);
  --bs-highlight-bg: #fcefdc;
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1200px;
  --bs-breakpoint-xxl: 1400px;
}
