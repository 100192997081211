$widths: 10, 20, 25, 30, 40, 50, 60, 70, 80, 90, 100;
$colors: rgb(149, 183, 83) 1, rgb(204, 51, 0) 2, rgb(229, 169, 26) 3, rgb(51, 102, 255) 4, #ff0f00 5,
  rgb(255, 102, 0) 6, rgb(255, 158, 1) 7, rgb(252, 210, 2) 8, rgb(248, 255, 1) 9;

@each $w in $widths {
  .w-#{$w} {
    width: #{$w + "%"} !important;
  }

  .wp-#{$w} {
    width: #{$w + "px"} !important;
  }
}

.flex {
  display: flex;
}

.f-c {
  display: flex;
  align-items: center;
}

.f-c-c {
  display: flex;
  align-items: center;
  justify-content: center;
}

.f-c-e {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.f-c-sb {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.fd-c {
  flex-direction: column;
}

.f-m {
  font-weight: 500;
}

.fs-18 {
  font-size: 18px;
}

.w-full {
  width: 100%;
}

.gp-20 {
  gap: 20px;
}

.b-rad-4 {
  border-radius: 4px;
}

.space-b {
  display: flex;
  justify-content: space-between;
}

.end {
  display: flex;
  justify-content: flex-end;

  text-align: end;
}

.h-100 {
  height: 100%;
}

.p-12-18 {
  padding: var(--s-12) var(--s-18);
}

.p-16-20 {
  padding: 16px 20px;
}

.p-16 {
  padding: 16px;
}

.pt-10 {
  padding-top: 10px;
}

.pr-20 {
  padding-right: 20px;
}

.ml-30 {
  margin-left: 30px;
}

.p-20 {
  padding: 20px;
}

.m-10 {
  margin: 10px;
}

.mb-7 {
  margin-bottom: 7px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.t-center {
  text-align: center;
}

.g-5 {
  gap: 5px;
}

.g-20 {
  gap: 20px;
}

.center {
  display: grid;
  place-items: center;

  text-align: center;
}

.x-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.f-20 {
  font-size: 20px;
}

.f-18 {
  font-size: 18px !important;
}

.f-bottom-right {
  position: fixed;
  bottom: 40px;
  right: 40px;
}

.right-bottom {
  width: 100%;
  margin-top: auto;
}

.min-h-100 {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.cp {
  cursor: pointer;
}

.bt {
  border-top: 1px solid #cfd7e1;
}

.bb {
  border-bottom: 1px solid #cfd7e1;
}

.br {
  border-right: 1px solid #cfd7e1;
}

.bl {
  border-left: 1px solid #cfd7e1;
}

@each $color, $index in $colors {
  .c-#{$index} {
    color: $color;
  }

  .bgc-#{$index} {
    background-color: $color;
  }
}

.c-red {
  color: red !important;
}

.c-green {
  color: green !important;
}

.c-blue {
  color: #228be6;
}

.c-orange {
  color: #fd7e14;
}

.page-title {
  margin: 2rem 0;
  font-size: 1.75rem;
  text-align: center;
}

.c-pointer {
  cursor: pointer;
}

.h-underline:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .page-title {
    margin: 1rem 0;
  }
}
