.btn {
  border: none;
  color: #515c6f;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.4375rem;
  letter-spacing: 0.72px;
  text-align: center;
  border-radius: 0.3125rem;
  background-color: #fff;
  cursor: pointer;

  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &-primary {
    background-color: #2997ff;
    color: #fff;
    border: 1px solid #2997ff;
    border-radius: 0.3125rem;

    &:hover {
      background-color: #fff;
      color: #2997ff !important;
      border: 1px solid #2997ff;
    }
  }

  &-default {
    color: #fff;
    background-color: #72809d;
  }

  &-danger {
    background-color: #ff3e1d;
    color: #fff !important;
  }

  &-warning {
    background-color: #fc3;
    color: #000;
  }

  &-dark {
    color: #fff;
    background-color: #000;
  }

  &.--with-loader {
    display: flex;
    align-items: center;
    gap: 10px;

    p {
      color: inherit;
      font-size: 16px !important;
      color: #fff !important;
    }

    &:hover {
      color: #2997ff !important;

      p {
        color: #2997ff !important;
      }
    }

    &:disabled {
      background-color: #2997ff;

      p {
        color: #fff !important;
      }
    }

    svg {
      opacity: 0.6;
      width: 20px;

      circle {
        stroke: #fff;
      }
    }
  }

  &:disabled {
    opacity: 0.6;
  }
}
