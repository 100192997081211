.form {
  &-label {
    display: inline-block;
    margin-bottom: 0.385rem;

    &.required {
      &::after {
        background-color: #ff3e1d;
        border-radius: 8px;
        content: "";
        display: inline-block;
        font-size: 60%;
        height: 5px;
        margin-left: 3px;
        vertical-align: super;
        width: 5px;
      }
    }
  }

  &-group {
    &__input-wrap {
      display: flex;
      position: relative;

      .currency {
        pointer-events: none;
        width: max-content;
        position: absolute;
        top: 8px;
      }
    }

    .--has-extra {
      input {
        border-radius: 4px 0 0 4px;
      }

      .extra-btn {
        border-radius: 0 4px 4px 0;
      }
    }
  }

  &-control {
    appearance: none;
    background-clip: padding-box;
    background-color: #fff;
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: 0.3rem;
    display: block;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.428571429;
    padding: 0.525rem 1rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    width: 100%;
    outline: none;

    &::shadow div {
      color: #ff3e1d !important;
    }

    &:focus {
      border: 1px solid #72809d;
      box-shadow: none;
    }

    &.number {
      font-size: 22px;
      font-weight: 600;
    }
  }
}