.select-tooltip {
  display: flex;
  gap: 7px;
  cursor: pointer;
  position: relative;
  z-index: 100;

  &__header {
    display: flex;
    gap: 7px;
    padding: 7px 9px;

    svg {
      opacity: 0.6;
    }

    &:hover,
    .active {
      .select-tooltip__label,
      svg {
        opacity: 1;
      }
    }
  }

  .form-group.react-datepicker {
    padding: 10px;
  }

  &__search-input {
    width: 100%;
    border: none !important;
  }

  &__wrapper {
    &.active {
      .select-tooltip__overlay {
        display: block;
      }

      .select-tooltip__tooltip {
        display: block;
      }
    }
  }

  &__overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
  }

  &__search-icon {
    position: absolute;
    right: 16px;
    top: 10px;
    width: 16px !important;
    opacity: 0.6 !important;
  }

  &__tooltip {
    display: none;
    position: absolute;
    top: calc(100% + 15px);
    background-color: #fff;
    border-radius: 4px;
    min-width: 275px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

    label {
      display: none;
    }

    form {
      width: 100%;
    }

    .btn-group {
      padding: 6px;
      display: flex;
      justify-content: space-between;
    }

    .form-group {
      width: 100%;
    }

    svg {
      width: 10px;
    }

    ul:first-child {
      max-height: 200px;
      overflow: auto;
      background-color: #fafafa;
    }

    li {
      display: flex;
      gap: 7px;
      padding: 7px 9px;

      &:hover {
        background-color: #6bc48d;
        p {
          color: #fff;
        }

        path {
          stroke: #fff;
          fill: #fff;
        }
      }
    }

    &__header {
      position: relative;
      display: flex;
    }

    &-list {
      border-top: 1px solid hsla(184, 9%, 62%, 0.2);
      border-bottom: 1px solid hsla(184, 9%, 62%, 0.2);
    }

    &::before {
      background-color: #fff;
      position: absolute;
      width: 10px;
      height: 10px;
      content: "";
      display: block;
      left: 20px;
      top: -5px;
      transform: rotate(45deg);
      box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    }
  }

  &__label {
    color: #333;
    opacity: 0.6;
  }

  &__count {
    height: max-content;
    background-color: #86cb43;
    color: #fff !important;
    padding: 0 5px;
    padding-bottom: 2px;
    border-radius: 4px;
  }
}
