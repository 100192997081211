.form {
  &__rating {
    width: max-content;
    display: flex;

    .tooltip {
      border-radius: 0;

      &:first-child li {
        border-radius: 4px 0 0 4px;
      }

      &:last-child li {
        border-right: 1px solid #cfd7e1;
        border-radius: 0 4px 4px 0;
      }
    }

    li {
      width: max-content;
      list-style: none;
      padding: 0 14px;
      line-height: 38px;
      border: 1px solid #cfd7e1;
      border-right: none;
      cursor: pointer;

      color: #536061;
      font-weight: 300;

      &.active {
        color: #fff;
        background-color: #6bc48d;
        border-color: transparent;
      }
    }
  }
}
