.ticket-form {
  &-upload {
    position: relative;
    display: flex;
    flex-direction: column;
    border: 1px solid #cfd7e1;
    border-radius: 4px;
    padding: 17px;

    background: rgba(47, 138, 245, 0.05);
    border: 1px dashed rgba(47, 138, 245, 0.7);
    border-radius: 10px;
    min-height: 120px;

    .placeholder {
      margin: auto 0;
      color: #2f8af5;
      font-weight: 600;
      font-size: 16px;
      line-height: 16px;
    }

    .bg-input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      opacity: 0;
    }

    &__btn {
      position: relative;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      margin: 0 auto;
      margin-top: 10px;

      background-color: #e3f0ff;
      // background: #2f8af5;
      border-radius: 12px;
      padding: 5px;
      width: 100%;
      // max-width: 248px;

      p {
        font-size: 16px;
        line-height: 20px;
        color: #2f8af5;
      }
      svg {
        width: 20px;
        opacity: 1;
        display: block;
      }

      path {
        fill: #2f8af5;
      }

      input {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 3;
        top: 0;
        left: 0;
        opacity: 0;
      }

      &-text {
        margin-left: 2px;
        color: #536061;
      }

      &:hover {
        // background-color: #2f8af5;
      }
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      padding: 5px;
      margin-top: 2px;
    }

    &__item {
      position: relative;
      z-index: 5;
      display: grid;
      grid-template-columns: auto 1fr auto;
      border-radius: 4px;
      width: max-content;
      padding: 2px;
      gap: 10px;

      background: #ffffff;
      border: 1px solid rgba(81, 92, 111, 0.1);
      border-radius: 10px;

      svg {
        display: block;
      }

      &-img {
        position: relative;
        background: linear-gradient(0deg, rgba(81, 92, 111, 0.15), rgba(81, 92, 111, 0.15)), #ffffff;
        border-radius: 8px;
        padding: 13px;
      }

      &-delete {
        display: flex;
        align-items: center;
        justify-self: center;
        width: 100%;
        height: calc(100% - 6px);
        padding: 0 8px;
        margin: 3px 0;
        border-left: 1px solid rgba(81, 92, 111, 0.1);

        svg {
          display: block;
          width: 12px;
          height: 16px;
        }

        path {
          fill: #ee1e50;
        }
      }
    }
    .file {
      &__title {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #2e3541;

        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        text-align: start;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }

      &__subtitle {
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        color: rgba(81, 92, 111, 0.6);
        text-align: start;
      }

      &__size {
        font-weight: 500;
        font-size: 12px;
        line-height: 17px;
        color: #2f8af5;
      }
    }
  }
}
