.card {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;

  &__img {
    position: relative;
    display: block;
    width: 100%;
    height: 185px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  a {
    display: block;
  }

  .approximate-quantity {
    width: max-content;
    display: flex !important;
    align-items: center;
    gap: 5px;

    color: var(--bs-warning);

    svg {
      display: block;
    }
  }

  .rating {
    display: flex;
    align-items: center;
    gap: 2px;
    position: absolute;
    top: 10px;
    right: 10px;

    padding: 2px 5px;
    border-radius: 10px;
    color: #fff;

    background: #000;

    svg {
      display: block;
      min-width: 20px;
    }

    path {
      fill: rgb(255, 210, 104);
    }

    span {
      display: block;
      line-height: 14px;
    }
  }

  h2 {
    font-size: 20px;
    font-weight: 300;
  }

  .store-link strong {
    color: #2997ff;

    &:hover {
      text-decoration: underline;
    }
  }

  li {
    position: relative;
    display: flex;
    align-items: center;
    gap: 3px;
    padding: 0.75rem 1.25rem;
    background-color: #fff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);

    &:first-child {
      flex-wrap: wrap;
      padding: 1.25rem;

      p {
        color: #6c757d;
        font-size: 16px;
        line-height: 24px;
        margin-top: 0.75px;
      }
    }

    svg {
      display: block;
      margin: 0 5px;
    }

    strong {
      margin: 0 5px;
    }

    &:last-child {
      border-bottom: 0;
      padding: 12px 10px;

      display: flex;
      align-items: center;
      justify-content: center;
      gap: 30px;

      button {
        margin-top: auto;
        background-color: #2997ff;
        color: #fff;
        border-radius: 0.3125rem;
        padding: 6px 12px;

        font-size: 1rem;
        font-weight: 400;
        line-height: 1.4375rem;
        letter-spacing: 0.72px;
        text-align: center;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
          border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

        &:hover {
          background-color: #fff;
          color: #2997ff;
          border: 1px solid #2997ff;
        }
      }

      a {
        border: none;
        color: #515c6f;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.4375rem;
        letter-spacing: 0.72px;
        text-align: center;
        border-radius: 0.3125rem;
        background-color: #fff;
        padding: 6px 12px;
        white-space: nowrap;


        &:hover {
          text-decoration: underline;
          outline: 0;
        }
      }
    }
  }
}