.form {
  &-switch {
    display: flex;
    position: relative;
    width: max-content;

    input {
      width: 100%;
      height: 100%;
      position: absolute;
      // visibility: hidden;
      opacity: 0;
      z-index: 3;
    }

    &__input {
      display: flex;
      align-items: center;
      border: 1px solid #cfd7e1;
      position: relative;
      border-radius: 20px;
      width: 30px;
      height: 19px;

      &::after {
        display: block;
        content: "";
        position: absolute;
        left: 3px;
        transition: transform 0.2s ease-in-out;

        width: 13px;
        height: 13px;
        border-radius: 50%;
        background-color: #b3bac4;
      }

      &.checked {
        background-color: #6a6cf7;
        border: none;

        &::after {
          background-color: #fff;
          transform: translateX(10px);
        }
      }
    }
  }
}