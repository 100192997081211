.tippy-tooltip {
  background-color: rgba(83, 96, 97, 0.794);
  margin-top: -35px;

  .arrow-regular {
    border-top: 4px solid rgba(83, 96, 97, 0.794) !important;
    bottom: -4px !important;
    border-width: 4px !important;
  }
}
