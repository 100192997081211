.permission {
  border: 1px solid #cfd7e1;
  max-width: 1000px;
  width: 100%;

  li {
    display: flex;
    gap: 10px;
    list-style: none;

    span {
      font-size: 14px;
    }
  }
}
