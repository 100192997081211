.modal {
  position: fixed;
  inset: 0;
  z-index: 1000;
  visibility: visible;
  min-height: 100vh;
  height: max-content;

  &__overlay {
    position: absolute;
    inset: 0;
    background-color: rgba(50, 71, 92, 0.5);
  }

  &__content {
    position: absolute;
    width: 100%;
    max-width: 720px;
    top: 50px;
    left: 50%;
    transform: translate(-50%, 0);
    border-radius: 8px;
    background-color: #fff;
    max-height: 90%;
    overflow: auto;
  }

  &__box {
    overflow: hidden auto;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: transparent;
    border-bottom: 1px solid hsla(184, 9%, 62%, 0.2);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    color: #2a3e51;
    padding: 15px 20px;
  }

  &__title {
    font-size: 20px;
    height: max-content;
  }
  &__close-icon {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);

    padding: 4px;
    border-radius: 8px;

    background-color: var(--light);
    transition: var(--transition);

    &:hover {
      transform: translate(calc(50% - 3px), calc(-50% + 3px));
      background-color: rgb(255, 255, 255);
    }

    svg {
      min-width: 22px;
    }

    path {
      fill: var(--text-color);
    }
  }

  &.open {
  }

  &.close {
    display: none;

    .modal__close-icon {
      transition: none;
    }
  }
}

.modal {
  &__give-money {
    .modal__content {
      overflow: visible;
    }

    .modal__box {
      overflow: visible;
    }
  }
}
