.form {
  &-check {
    display: flex;
    &-input {
      --bs-form-check-bg: #fff;
      appearance: none;
      background-color: var(--bs-form-check-bg);
      background-image: var(--bs-form-check-bg-image);
      background-position: 50%;
      background-repeat: no-repeat;
      background-size: contain;
      border: var(--bs-border-width) solid var(--bs-border-color);
      border-radius: 0.25em;
      height: 1.143em;
      margin-top: 0.1427857145em;
      print-color-adjust: exact;
      vertical-align: top;
      width: 1.143em;
      min-width: 1.143em;
      cursor: pointer;

      &:checked {
        background-color: #6a6cf7;
        border-color: #6a6cf7;
        --bs-form-check-bg-image: url("/assets/images/icons/checked.svg");
      }

      &:disabled {
        background-color: rgba(118, 118, 118, 0.1);
        border: none;
      }

      &.indeterminate {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background-color: #6a6cf7;
        border-color: #6a6cf7;
        &::before {
          display: block;
          content: "";
          width: 5px;
          height: 2px;
          position: absolute;
          background-color: #fff;
        }
      }
    }

    &-label {
      display: inline-block;
      margin-left: 10px;
      cursor: pointer;
    }
  }
}
