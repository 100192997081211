.form {
  &__textarea {
    width: 100%;
    display: block;
    padding: 5px;
    border: 1px solid #cfd7e1;
    border-radius: 4px;

    &::placeholder {
      color: #849596;
    }
  }
}
