* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

html {
  width: 100vw;
  overflow: hidden visible;
  background-color: #fff;
  height: 100%;
  line-height: 1.42857143;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

body {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  color: var(--bs-body-color);
  background-color: #fafbfd;
}

img,
picture,
video {
  display: block;
  max-width: 100%;
}

input,
textarea,
button,
select {
  font: inherit;
  border: none;
}

a {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
}

h1 {
  font-size: var(--f-96);
  line-height: var(--f-112);
}

h2 {
  font-size: var(--f-60);
  line-height: var(--f-72);
}

h3 {
  font-weight: 400;
  font-size: 1.75rem;
  line-height: 1.2;
}

h4 {
  font-size: var(--f-32);
  line-height: var(--f-40);
}

h5 {
  font-size: var(--f-24);
  line-height: var(--f-32);
}

h6 {
  font-size: var(--f-20);
  line-height: var(--f-32);
}

li {
  list-style: none;
}

a {
  color: inherit;
  cursor: pointer;
}

button {
  cursor: pointer;
}

input {
  cursor: pointer;
}

[class*="btn"] {
  cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.app {
  min-width: calc(100vw - 15px);
  min-height: 100vh;
}

.container {
  max-width: 900px;
  width: 100%;
  margin: 0 auto;

  &.--page {
    padding: 20px;
    background-color: #fff;
  }
}

.form-group {
  position: relative;
  margin-bottom: 0;
  padding-bottom: 20px;
}

.form__error {
  font-size: 16px !important;
  color: red !important;
}

.modal-news {
  .modal__header {
    display: none;
  }

  .goback-header__container {
    display: none;
  }

  .modal__content {
    transform: none;
    height: 100vh;
    max-height: none;
    top: 0;
    right: 0;
    left: auto;
    border-radius: 0;
    overflow: visible;
  }

  .close-icon {
    display: grid;
    place-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 44px;
    height: 44px;
    border-radius: 20px 0px 0px 20px;
    transform: translateX(-100%);

    box-shadow: inset -2px -3px 10px rgba(0, 0, 0, 0.1);

    background-color: #fff;

    svg {
      width: 18px;
      height: 18px;
    }
  }
}

.block-info {
  padding: 7px 15px;
  margin: 10px 0;
  border-radius: 10px;
  background-color: var(--bs-blue);
  color: #fff;
  font-size: 22px;
  font-weight: 500;
}

.block-alert {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 8px 15px;
  margin: 5px 0;
  margin-bottom: 0;
  border-radius: 10px;
  color: #fff;
  font-size: 22px;
  line-height: 22px;
  font-weight: 500;
  background-color: var(--bs-red);

  .icon {
    width: 22px;
    height: 22px;

    path {
      fill: #fff;
    }
  }
}

.modal-x-icon {
  display: none !important;
  width: 18px;
  height: 18px;
  cursor: pointer;

  @media (max-width: 600px) {
    display: block !important;
  }
}

.order-details {
  background-color: #e6e7fc;

  div {
    display: flex;
    gap: 8px;
  }
}

.order {
  &__phone {
    display: flex;
    align-items: center;
    font-size: 24px;
    color: rgb(91, 192, 222);

    &:hover {
      text-decoration: underline;
    }
  }

  &__client-full-name {
    font-size: 1.2rem;
    white-space: nowrap;
    color: rgb(83, 96, 97);
    font-weight: 500;
    line-height: 1.2;
    margin-bottom: 0.5rem;
    margin-top: 0;
  }

  &__details {
    ul {
      margin: 20px 0;
    }

    li {
      display: grid;
      grid-template-columns: 1.2fr 1fr;
      list-style: none;

      padding: 10px 15px;
      border-bottom: 1px solid #c4d0d3;

      &:nth-child(2n) {
        background-color: #f7f9fb;
      }
    }
  }

  &__avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    min-width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 10px;

    color: rgb(255, 0, 0);
  }
}

.penalty-status {
  &-completed {
    background-color: var(--bs-success) !important;

    * {
      color: #fff !important;
    }
  }

  &-rejected {
    background-color: var(--bs-danger) !important;

    * {
      color: #fff !important;
    }
  }
}
