.form {
  &-upload {
    border: 1px solid #cfd7e1;
    border-radius: 4px;
    padding: 3px;

    &__btn {
      display: flex;
      align-items: center;
      position: relative;
      border-radius: 4px;
      padding: 3px 6px;
      background-color: transparent;
      gap: 5px;

      svg {
        width: 12px;
        opacity: 0.5;
      }

      input {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 3;
        top: 0;
        left: 0;
        opacity: 0;
      }

      &-text {
        margin-left: 2px;
        color: #536061;
      }

      &:hover {
        background-color: rgba(215, 225, 234, 0.7);
      }
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
      gap: 15px;
      padding: 5px;
      margin-top: 2px;
      border-top: 1px solid #cfd7e1;
    }

    &__item {
      display: flex;
      border-radius: 4px;
      padding: 5px;
      width: max-content;
      gap: 10px;

      &:hover {
        background-color: rgba(215, 225, 234, 0.7);
      }

      img {
        max-width: 93px;
      }

      &-img {
        position: relative;
      }

      &-delete {
        display: none;
        align-items: center;
        justify-self: center;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        z-index: 1;
        background-color: #53606169;

        svg {
          width: 30px;
          height: auto;
          margin: 0 auto;
        }

        path {
          fill: rgba(255, 255, 255, 0.509);
        }
      }

      &:hover {
        .form-upload__item-delete {
          display: flex;
        }
      }
    }

    &__any-item {
      display: flex;
      gap: 5px;
      align-items: center;

      &-delete svg {
        display: block;
        width: 14px;
        height: 14px;
        cursor: pointer;
      }

      path {
        fill: red;
      }
    }
  }
}
